import DesktopHomepage from "./desktop/desktophomepage";
import MobileHomepage from "./mobile/mobilehomepage";


function Homepage() {
  return (
    <div>
    <DesktopHomepage />
    <MobileHomepage />

    </div>
  );
}

export default Homepage;
