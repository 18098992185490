import "./homepage.css";
import "./notebook.css";
import "./zakazane-styly.css";

// Knihovny
import { FaRegPlayCircle } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import 'animate.css';
import { MdNavigateNext } from "react-icons/md";
import { CiCircleCheck } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";





function DesktopHomepage() {
  return (
    <div>

        <img className="superpatch-obrazek" src="./img/background-desktop.jpg"></img>

        <p className="superpatch-desktop-h1 animate__animated animate__backInLeft">SUPER PATCH</p>
        <p className="superpatch-desktop-h2 animate__animated animate__backInLeft">100% bez léků a neinvazivní</p>

        <p className="superpatch-desktop-ceska-republika animate__animated animate__backInLeft">Zaručená technologie</p>

    <a href="https://www.youtube.com/watch?v=ypBJih9oPi8" target="blank">
        <FaRegPlayCircle className="superpatch-vide-tlacitko animate__animated animate__bounceIn animate__delay-2s" />
    </a>
    
        {/* <IoMenu className="superpatch-vide-tlacitko2 animate__animated animate__backInRight" /> */}


    <div className="superpatch-desktop-pozadi"></div>

    <p className="superpatch-desktop-h1-2">Nejvíce populární</p>
    <p className="superpatch-desktop-h5">Nakupujte s 30denní zárukou vrácení peněz bez rizika</p>

    <img src="img/victory.png"  className="superpatch-desktop-img1"></img>
    <img src="img/liberty.png"  className="superpatch-desktop-img2"></img>
    <img src="img/peace.png"    className="superpatch-desktop-img3"></img>


    <p className="superpatch-desktop-img-text1">Victory</p>
    <p className="superpatch-desktop-img-text1-2">Síla, síla, rovnováha, obratnost a regenerace jsou pilíři nejvyšší úrovně sportovního výkonu.</p>

    <p className="superpatch-desktop-img-text2">Liberty</p>
    <p className="superpatch-desktop-img-text2-2">Zlepšení pohybu a změny směru jsou základem zdravého životního stylu a každodenní aktivity.</p>

    <p className="superpatch-desktop-img-text3">Peace</p>
    <p className="superpatch-desktop-img-text3-2">Stres je jedním z největších nepřátel kvality našeho života.</p>

    <a href="https://renatalinhartova.superpatch.com/">
        <button className="superpatch-desktop-tlacitko1">NAKUPOVAT NYNÍ</button>
    </a>

    <p className="superpatch-desktop-button-text1">Návod k registraci on-line</p>

    <p className="superpatch-desktop-h1-3">Podívejte se na technologie v akci</p>
    <p className="superpatch-desktop-h3-3">Získejte výbušnější starty a silnější zakončení</p>
    
    <a href="https://www.youtube.com/watch?v=XMcBWkOdyGg" target="blank">
        <img src="./img/video-1-updatge.jpg" className="superpatch-video-1"></img>
    </a>

    <a href="https://www.youtube.com/watch?v=WFwIscRIhak" target="blank">
        <img src="./img/video-2-update.jpg" className="superpatch-video-2"></img>
    </a>

    <p className="superpatch-desktop-h1-4">Nakupujte dle preferencí</p>
    <div className="superpatch-podtrzeni-1"></div>


    <img src="./img/bolest.PNG" className="obrazek1"></img>
    <img src="./img/druhy.PNG" className="obrazek2"></img>
    <img src="./img/treti.PNG" className="obrazek3"></img>

    <MdNavigateNext className="superpatch-desktop-sipka1" />
    <MdNavigateNext className="superpatch-desktop-sipka2" />
    
    <p className="superpatch-desktop-h1-5">Staňte se influencerem Super Patch</p>
    <p className="superpatch-desktop-h3-4">Máte zájem stát se součástí rodiny influencerů Super Patch? Podělte se s námi o svůj příběh a náš tým jej posoudí a ozve se vám!</p>

    <div className="superpatch-desktop-pozadi-prostred"></div>

   
        <img src="./img/dylan.jpeg" className="superpatch-desktop-dylan"></img>
        <img src="./img/cody.jpeg" className="superpatch-desktop-cody"></img>
        <img src="./img/luke.jpeg" className="superpatch-desktop-luke"></img>
        <img src="./img/samieryah.jpeg" className="superpatch-desktop-samieryah"></img>

        <a href="https://renatalinhartova.superpatch.com/" target="blank">
            <button className="superpatch-desktop-tlacitko2">KONTAKTUJTE NÁS</button>
        </a>

        <div className="superpatch-desktop-pokracovani-pozadi1"></div>

        <p className="superpatch-desktop-h1-6">Technologie v akci</p>


        <img src="./img/superpatch-technologie.jpg" className="superpatch-desktop-video-technologie"></img>

        <a href="https://www.youtube.com/watch?v=ypBJih9oPi8" target="blank">
            <FaRegPlayCircle className="superpatch-vide-tlacitko3" />
        </a>


        <p className="superpatch-desktop-h3-5">Super věda</p>
        <p className="superpatch-desktop-h3-6">Vibrotaktilní technologie změnila vše...</p>

        <p className="superpatch-desktop-h3-7">Technologie Super Patch využívá speciální vzor a vibrotaktilní technologii, která vám pomůže přejít z pocitu blaha do pocitu HUZZAH!</p>
        <p className="superpatch-desktop-h3-8">Jakmile se náplast Super Patch dostane do kontaktu s vaší pokožkou, spustí v mozkových nervových sítích reakci, která vám pomůže řešit každodenní problémy.</p>

        <img src="./img/patch-vibrating-pink.png" className="superpatch-desktop-obrazek-technologie1"></img>

       
        <p className="superpatch-desktop-h3-9">Spuštění reakce</p>
        <p className="superpatch-desktop-h3-10">Mozek a nervový systém tuto stimulaci zpracovává a dochází k vodopádu wellness reakcí, od větší energie po lepší rovnováhu a kvalitu života.</p>

        <img src="./img/TriggerAResponse-2.gif" className="superpatch-desktop-obrazek-technologie2"></img>


        <div className="superpatch-desktop-oranzove"></div>
        <p className="superpatch-desktop-h1-7">NEJČASTĚJŠÍ OTÁZKY</p>


        <p className="superpatch-desktop-otazka-1">Jak technologie Super Patch funguje?</p>

        <p className="superpatch-desktop-odpoved-1">Technologie uvnitř náplasti Super Patch má jedinečně uspořádané hřebeny, které vypadají jako QR kód, a když se tyto hřebeny dotknou naší kůže, naše buňky vysílají jedinečné signály, které komunikují s nervovým systémem našeho těla.</p>
        <p className="superpatch-desktop-odpoved-1-2">Vynálezce těchto náplastí objevil různé signály, které pracují s fyzickými, mentálními a emocionálními funkcemi těla, každá jiná Super náplast má jedinečný hřebenový vzor (QR kódy), který pomáhá s širokou škálou wellness a výkonnostních výhod.</p>

        <p className="superpatch-desktop-otazka-2">Je technologie Super Patch bezpečná?</p>
        <p className="superpatch-desktop-odpoved-2">Ano! Technologie Super Patch byla strategicky zkoumána a navržena tak, aby poskytovala 100% bezlékové, přírodní a neinvazivní řešení pro lidi, kteří mají problémy s bolestí, rovnováhou a pohyblivostí, a od té doby vyvinula mnoho dalších řešení, která pomáhají zlepšit kvalitu života více lidí. Účinky jsou navíc téměř okamžité!</p>

        <p className="superpatch-desktop-otazka-3">Jak používáte technologii Super Patch?</p>
        <p className="superpatch-desktop-odpoved-3">Použití technologie Super Patch nemůže být jednodušší! Technologie je strategicky umístěna na všech výrobcích tak, aby byla v kontaktu s vaší pokožkou, a tím iniciovala vibroaktilní stimulaci.</p>

        <p className="superpatch-desktop-otazka-4">Jaké jsou vaše zásady vracení zboží?</p>
        <p className="superpatch-desktop-odpoved-4">Super Patch nabízí plnou 30denní záruku vrácení peněz, takže máte dostatek času produkt vyzkoušet a zažít jeho výhody z první ruky.</p>

        <CiCircleCheck className="superpatch-desktop-kolecko1" />
        <CiCircleCheck className="superpatch-desktop-kolecko2" />
        <CiCircleCheck className="superpatch-desktop-kolecko3" />
        <CiCircleCheck className="superpatch-desktop-kolecko4" />

        <div className="superpatch-desktop-zelena"></div>

        <div className="superpatch-desktop-bila1"></div>

        <p className="superpatch-desktop-h1-8">ZÁJEM O SPOLUPRÁCI</p>
        <p className="superpatch-desktop-h3-11">Sledujte, jak Super Patch mění životy každý den!</p>

        <a href="https://www.instagram.com/_superpatchcz_/" target="blank">
            <FaInstagram className="superpatch-desktop-instagram-1" />
        </a>

        <a href="https://www.facebook.com/profile.php?id=61559448366818" target="blank">
            <FaFacebook className="superpatch-desktop-facebook-2" />
        </a>

        <a href="https://www.tiktok.com/@naplasti_superpatchcz" target="blank">
            <FaTiktok className="superpatch-desktop-tiktok-3" />
        </a>
        <p className="superpatch-desktop-h3-12">V zájmu spolupráce nás prosím kontaktujte prostředkem <b>sociálních sítí</b>.</p>

        <div className="superpatch-desktop-fialova"></div>

        <div className="superpatch-desktop-bila2"></div>


        <p className="superpatch-desktop-h1-9">KONTAKUJTE NÁS</p>

        <div className="superpatch-desktop-fialova-2"></div>

        <p className="superpatch-desktop-telefon">Telefon : </p>
        <p className="superpatch-desktop-telefon-cislo">721 005 668</p>


        <p className="superpatch-desktop-mail">E-mail : </p>
        <p className="superpatch-desktop-mail-adresa">superpatchsz@gmail.com</p>


        <p className="superpatch-desktop-ic">IČ : </p>
        <p className="superpatch-desktop-ic-cislo">71069283</p>






    </div>
  );
}

export default DesktopHomepage;
