import "./homepage.css";
import "./zakazanestyly.css";

// Knihovny
import { IoMenu } from "react-icons/io5";
import "animate.css";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";



function MobileHomepage() {
  return (
    <div>

    <div className="pozadi"></div>

    <img className="superptach-mobile-pozadi-2" src="./img/homepage-mobile.jpg"></img>

    <IoMenu className="superpatch-mobile-menu"></IoMenu>

    <p className="superpatch-mobile-h1-1 animate__animated animate__backInLeft">SUPER PATCH</p>
    <p className="superpatch-mobile-h3-1 animate__animated animate__backInLeft">100% bez léků a neinvazivní</p>

    <a href="https://www.youtube.com/watch?v=wsK3n6zLwX4" target="blank">
      <FaRegPlayCircle className="superpatch-mobile-play-button-1" />
    </a>

      <p className="superpatch-mobile-homepage-text-1">Více informací</p>

      <FaChevronDown className="superpatch-mobile-ikonka-1" />

      <div className="pozadi2"></div>

      <p className="superpatch-mobile-h1-2">Nejvíce populární</p>
      <p className="superpatch-mobile-h3-2">Nakupujte s 30denní zárukou vrácení peněz bez rizika</p>

      <img src="./img/liberty.png" className="superpatch-mobile-liberty"></img>

    <a href="https://renatalinhartova.superpatch.com/">
      <button className="superpatch-mobile-tlacitko-1">NAKUPOVAT NYNÍ</button>
    </a>

      <p className="superpatch-mobile-h3-3">Návod k registraci on-line</p>



    <div className="superpatch-mobile-technologie-background-fialova">
      <img src="./img/superpatch-technologie-1.jpg" className="superpatch-mobile-technologie"></img>

      <p className="superpatch-mobile-h1-3">Technologie v akci</p>
      <p className="superpatch-mobile-h3-4">Technologie uvnitř náplasti Super Patch má jedinečně uspořádané hřebeny, které vypadají jako QR kód, a když se tyto hřebeny dotknou naší kůže, naše buňky vysílají jedinečné signály, které komunikují s nervovým systémem našeho těla.</p>

      </div>



      <img src="./img/video-1-updatge.jpg" className="superpatch-mobile-youtube-1"></img>
      <img src="./img/video-2-update.jpg" className="superpatch-mobile-youtube-2"></img>

      <p className="superpatch-mobile-h1-4">Ulevte od svých problémů</p>
      <p className="superpatch-mobile-h1-5">Nakupujte dle preferencí</p>


      <img src="./img/bolest.PNG" className="superpatch-mobile-typy-1"></img>
      <img src="./img/druhy.PNG" className="superpatch-mobile-typy-2"></img>
      <img src="./img/treti.PNG" className="superpatch-mobile-typy-3"></img>

      <p></p>
      <FaChevronDown className="superpatch-mobile-typy-ikonka" />

      <p className="superpatch-mobile-h1-6">Nevíte si rady ?</p>
      <p className="superpatch-mobile-h1-7">Poradíme Vám</p>

    <a href="https://www.instagram.com/_superpatchcz_/" target="blank">
      <FaInstagram className="superpatch-mobile-ikonka-ig-1" />
    </a>

    <a href="https://www.facebook.com/profile.php?id=61559448366818" target="blank">
      <FaFacebook className="superpatch-mobile-ikonka-fb-1" />
    </a>

    <a href="https://www.tiktok.com/@naplasti_superpatchcz" target="blank">
      <FaTiktok className="superpatch-mobile-ikonka-tt-1" />
    </a>

      <li className="superpatch-mobile-info-1">Máte zájem o vzorek ?</li>
      <li className="superpatch-mobile-info-2">Máte otázku k produktu ?</li>
      <li className="superpatch-mobile-info-2">Chcete doporučení ?</li>

      <img src="./img/superpatch-czech-naplasti.jpg" className="superpatch-mobile-dermatologicky-testovano"></img>

      <p className="superpatch-mobile-h1-8">Dermatologicky testováno a schváleno</p>

      <p className="superpatch-mobile-h1-9">Všechny naše náplasti byly navrženy tak, aby byly bezpečné a snadno použitelné každý den.</p>
      <p className="superpatch-mobile-h1-10">Jsou nejen dermatologicky a klinicky testovány a schváleny, ale také neobsahují léčiva, lepek, parabeny, latex, jsou hypoalergenní, geneticky nemodifikované a certifikované jako bezpečné pro pokožku.</p>

      <p className="superpatch-mobile-dermatologicky-testovano-vice-informaci">Více informací zde</p>

      <div className="superpatch-mobile-pozadi-oranzova">

      <br></br>
      <p className="superpatch-mobile-h1-11">FAQ</p>

      <p className="superpatch-mobile-h1-12">Jak technologie Super Patch funguje?</p>
      <p className="superpatch-mobile-h1-13">Technologie uvnitř náplasti Super Patch má jedinečně uspořádané hřebeny, které vypadají jako QR kód, a když se tyto hřebeny dotknou naší kůže, naše buňky vysílají jedinečné signály, které komunikují s nervovým systémem našeho těla.</p>

      <br></br>
      <p className="superpatch-mobile-h1-12">Jak používát technologii Super Patch?</p>
      <p className="superpatch-mobile-h1-13">Použití technologie Super Patch nemůže být jednodušší! Technologie je strategicky umístěna na všech výrobcích tak, aby byla v kontaktu s vaší pokožkou, a tím iniciovala vibroaktilní stimulaci.</p>

      <br></br>
      <p className="superpatch-mobile-h1-12">Je technologie Super Patch bezpečná?</p>
      <p className="superpatch-mobile-h1-13">Ano! Technologie Super Patch byla strategicky zkoumána a navržena tak, aby poskytovala 100% bezlékové, přírodní a neinvazivní řešení pro lidi, kteří mají problémy s bolestí, rovnováhou a pohyblivostí, a od té doby vyvinula mnoho dalších řešení, která pomáhají zlepšit kvalitu života více lidí. Účinky jsou navíc téměř okamžité!</p>

      <br></br>
      <p className="superpatch-mobile-h1-12">Jaké jsou zásady vracení zboží?</p>
      <p className="superpatch-mobile-h1-13">Super Patch nabízí plnou 30denní záruku vrácení peněz, takže máte dostatek času produkt vyzkoušet a zažít jeho výhody z první ruky.</p>

      </div>
      <div className="superpatch-mobile-pozadi-zelena">

      <p className="superpatch-mobile-h1-14">ZÁJEM O SPOLUPRÁCI</p>

      <p className="superpatch-mobile-h1-15">Sledujte, jak Super Patch <b>mění životy</b> každý den!</p>

    <a href="https://www.instagram.com/_superpatchcz_/" target="blank">
      <FaInstagram className="superpatch-mobile-ikonka-ig-2" />
    </a>

    <a href="https://www.facebook.com/profile.php?id=61559448366818" target="blank">
      <FaFacebook className="superpatch-mobile-ikonka-fb-2" />
    </a>

    <a href="https://www.tiktok.com/@naplasti_superpatchcz" target="blank">
      <FaTiktok className="superpatch-mobile-ikonka-tt-2" />
    </a>


      <p className="superpatch-mobile-h1-16">V zájmu spolupráce nás prosím kontaktujte prostředkem sociálních sítí.</p>

      </div>

      <div className="superpatch-mobile-pozadi-bila-3"></div>
      <br></br><br></br>
      <p className="superpatch-mobile-h1-17">KONTAKTNÍ INFORMACE</p>

      <p className="superpatch-mobile-telefon">telefon</p>
      <p className="superpatch-mobile-telefon-cislo">721 005 668</p>

      <p className="superpatch-mobile-telefon">e-mail</p>
      <p className="superpatch-mobile-telefon-cislo">superpatchsz@gmail.com</p>

      <br></br>

      <a href="https://www.instagram.com/_superpatchcz_/" target="blank">
      <FaInstagram className="superpatch-mobile-ikonka-ig-2" />
    </a>

    <a href="https://www.facebook.com/profile.php?id=61559448366818" target="blank">
      <FaFacebook className="superpatch-mobile-ikonka-fb-2" />
    </a>

    <a href="https://www.tiktok.com/@naplasti_superpatchcz" target="blank">
      <FaTiktok className="superpatch-mobile-ikonka-tt-2" />
    </a>

      <p className="superpatch-mobile-gbtr">Ochrana osobních údajů</p>

      <div className="superpatch-mobile-footer">
        <p className="superpatch-mobile-footer-text">©2024 - SuperPatch.cz</p>
      </div>

    </div>
  );
}

export default MobileHomepage;
