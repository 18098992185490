import './App.css';
import Homepage from "./komponenty/Homepage";


function App() {
  return (
    <div>
      <Homepage />
    </div>
  );
}

export default App;
